import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollTop from "../ScrollTop.js";
// import SideBox from "../SideBox.js";
import Content from "../../components/ContentHeader.js";
import Route from "../../components/Route.js";

// css
import "../../css/News.css";
import "../../css/NewsPage.css";
import "../../css/Conference.css";

// img
import Cont03 from "../../assets/img/web_cont03.png";

function Contents03() {
  return (
    <div className="webinar_wrap">
      <ScrollTop />

      {/* <SideBox /> */}

      {/* 페이지 타이틀명 설정 */}
      <Helmet>
        <title>레인보우브레인 | 지능형자동화 전문기업</title>
      </Helmet>
      <Content
        title="CONFERENCE"
        title1="콘텐츠를 통해 인사이트를 얻어보세요"
      />

      <Route route1="MEDIA" route2="CONFERENCE"></Route>

      <div className="contents_wrap content_bg">
        <div className="container">
          <div className="news_box">
            <div className="news_badge">
              <span>ONLINE</span>
            </div>

            <div className="news_content">
              <img src={Cont03} alt="이미지" />

              <div className="text_box">
                <span>행사 소개</span>
                <table className="intro">
                  <tr>
                    <th>주제</th>
                    <td style={{ lineHeight: "26px" }}>
                      생성형 AI 랜드스케이프 확장
                      <br />
                      Session 1. 생성형 AI 기술과 인프라선택 기준 사례 Q&A
                      <br />
                      Session 2. LLM에서 LMM으로 생성형 AI 랜드스케이프 확장
                      <br />
                      Session 3. 레인보우브레인 &lsaquo;Trend Sensing
                      Board&rsaquo; 소개
                    </td>
                  </tr>
                  <tr>
                    <th>일시</th>
                    <td>2024년 5월 29일(수) | 오후 02:00~03:00 (QA 포함)</td>
                  </tr>
                  {/* <tr>
                    <th>신청</th>
                    <td>아래 [등록하기] 클릭하여 신청 양식 제출</td>
                  </tr> */}
                  <tr>
                    <th>비용</th>
                    <td>무료</td>
                  </tr>
                  <tr>
                    <th>장소</th>
                    <td>온라인 진행 행사</td>
                  </tr>
                  <tr>
                    <th>채널</th>
                    <td>YouTube</td>
                  </tr>
                </table>

                <p style={{ paddingTop: "32px" }}>
                  챗GPT 뿐만 아니라 다양한 모달리티에서 생성형 AI가 적극적으로
                  활용되는 한 해가 될 것으로 예상한다.
                  <br />
                  텍스트는 물론이고 이미지, 동영상, 오디오로 확장되고, 이를
                  통합하는 멀티 모달 생성형 AI가 등장하면서 LLM에서
                  L(Language)를 대신하는 다른 용어를 찾으려는 움직임도 보이고
                  있다.
                </p>
              </div>
            </div>

            <a
              href="https://m.site.naver.com/1o0A2"
              target="_blank"
              rel="noopener noreferrer"
              className="btn_webinar"
            >
              시청하기
            </a>

            <div className="news_btn">
              <Link to="/media/Conference">
                <button className="btn_prev">목록</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contents03;
