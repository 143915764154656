import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import ScrollTop from "./ScrollTop";
import Content from "../components/ContentHeader";
import Route from "../components/Route";

// img
import download from "../assets/img/download_icon.png";
import buttonArrow from "../assets/img/button_arrow_icon.png";
import intro1 from "../assets/img/intro1.png";
import intro2 from "../assets/img/intro2.png";
import advantage1 from "../assets/img/intro_icon1.png";
import advantage2 from "../assets/img/intro_icon2.png";
import advantage3 from "../assets/img/intro_icon3.png";
import advantage4 from "../assets/img/intro_icon4.png";
import intro3 from "../assets/img/intro3.png";
import icon1 from "../assets/img/intro_icon5.png";
import icon2 from "../assets/img/intro_icon6.png";
import icon3 from "../assets/img/intro_icon7.png";
import icon4 from "../assets/img/intro_icon8.png";
import icon5 from "../assets/img/intro_icon9.png";
import icon6 from "../assets/img/intro_icon10.png";

// -- css -- //
import "../css/IntroductionPage.css";

function IntroductionPage() {
  const handleCmpanyProfile = async () => {
    window.open(
      "https://s3.ap-northeast-2.amazonaws.com/rbrain.co.kr/images/rainbowbrain2023.pdf",
      "_blank"
    );
  };

  return (
    <div className="intro_wrap">
      <ScrollTop />

      {/* 페이지 타이틀명 설정 */}
      <Helmet>
        <title>레인보우브레인 | 지능형자동화 전문기업</title>
        <meta
          name="description"
          content="(주)레인보우브레인은 인공지능 및 업무자동화 분야 솔루션 개발 및 구축 전문 기업입니다."
        ></meta>
      </Helmet>

      <Content title="RAINBOW BRAIN"></Content>
      <div className="header_tab">
        <div className="container sub_title4">
          <Link to="/company/introduction" className="intro header_active">
            <span>INTRODUCTION</span>
          </Link>
          <Link to="/company/support" className="support">
            <span>SUPPORT</span>
          </Link>
        </div>
      </div>

      <Route route1="RAINBOW BRAIN" route2="INTRODUCTION"></Route>
      <div className="content_bg intro_visual">
        <div className="container">
          <div className="visual_textbox">
            <h2 className="heading2">
              (주)레인보우브레인은 인공지능 및 업무자동화 분야
              <br />
              솔루션 개발 및 구축 전문 기업입니다.
            </h2>
            <p className="contents1">
              RPA 기획자, 프로세스 분석가, 개발자, 로봇 엔지니어 등으로 구성된
              RPA 전문 인력과 함께
              <br />
              고객의 업무 혁신을 도와드리고 있습니다.
              <br />
              <br />
              저희 (주)레인보우브레인은 최다 RPA 구축 기업으로, 누적된 경험과
              노하우를 통해
              <br />
              RPA 도입을 위한 업무 선정, 프로세스 이해 및 안정화를 지속적으로
              지원합니다.
            </p>
            <div>
              <button
                className="blue_btn button1"
                onClick={handleCmpanyProfile}
              >
                회사소개서 다운로드
                <img src={download} alt="download" />
              </button>
              <NavLink to="/business/rpa" style={{ marginLeft: "20px" }}>
                <button className="blue_btn button1">
                  RPA 소개 바로 가기
                  <img src={buttonArrow} alt="RPA 소개 바로가기" />
                </button>
              </NavLink>
            </div>
          </div>

          <img src={intro1} alt="intro1" />
        </div>
      </div>

      {/* <div className="intro_video">
        <div className="container">
          <h2 className="heading1">RPA + AI 구축사례</h2>
          <div className="iframe">
            <iframe
              src="https://www.youtube.com/embed/JEgWAGks5dQ?controls=0"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
          </div>
          <div>
            <button className="blue_btn button1">
              영상 더 보러가기
              <img src={buttonArrow} alt="영상 더 보러가기" />
            </button>
          </div>
        </div>
      </div> */}

      <div className="intro_advantage">
        <div className="container">
          <img src={intro2} alt="intro2" />
          <div className="advantage_textbox">
            <h2 className="heading1">ADVANTAGE</h2>
            <ul className="advantage_wrap">
              <li>
                <div className="wrap_bg">
                  <img src={advantage1} alt="icon_1" />
                </div>
                <div className="wrap_text">
                  <p className="sub_title4">RPA SW 공급 구축망 컨설팅</p>
                  <span className="contents2">
                    글로벌 No.1 RPA솔루션 Automation Anywhere 공식 'Pinnacle
                    파트너' 플래티넘 레벨 파트너십 획득
                  </span>
                </div>
              </li>
              <li>
                <div className="wrap_bg">
                  <img src={advantage2} alt="icon_2" />
                </div>
                <div className="wrap_text">
                  <p className="sub_title4">RPA as a Service</p>
                  <span className="contents2">
                    챗봇, RPA, 인공지능을 결합한 지능형 클라우드 서비스 플랫폼
                    제공
                  </span>
                </div>
              </li>
              <li>
                <div className="wrap_bg">
                  <img src={advantage3} alt="icon_3" />
                </div>
                <div className="wrap_text">
                  <p className="sub_title4">인공지능 SW 서비스 개발</p>
                  <span className="contents2">
                    IBM 왓슨 솔루션, ML 솔루션 공급 및 데이터 분석 컨설팅
                  </span>
                </div>
              </li>
              <li>
                <div className="wrap_bg">
                  <img src={advantage4} alt="icon_4" />
                </div>
                <div className="wrap_text">
                  <p className="sub_title4">AI 데이터 분석 및 컨설팅</p>
                  <span className="contents2">RPA 포탈 및 대시보드</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="intro_rpa container">
        <h2 className="heading1">RPA REFERENCE</h2>
        <p className="contents1">
          (주)레인보우브레인은 제조, 금융, 유통, 서비스 등 다양한 분야에서 100여
          고객사 2,000건 이상의 업무 레퍼런스를 보유하고 있습니다.
        </p>
        <ul className="rpa_wrap">
          <li>
            <img src={icon1} alt="icon1" />
            <p className="sub_title4">
              제조업
              <br />
              <span className="contents3">Manufacturing</span>
            </p>
          </li>
          <li>
            <img src={icon2} alt="icon2" />
            <p className="sub_title4">
              금융업
              <br />
              <span className="contents3">Finance</span>
            </p>
          </li>
          <li>
            <img src={icon3} alt="icon3" />
            <p className="sub_title4">
              유통업
              <br />
              <span className="contents3">Distribution</span>
            </p>
          </li>
          <li>
            <img src={icon4} alt="icon4" />
            <p className="sub_title4">
              서비스
              <br />
              <span className="contents3">Services</span>
            </p>
          </li>
          <li>
            <img src={icon5} alt="icon5" />
            <p className="sub_title4">
              공공사업
              <br />
              <span className="contents3">Public Services</span>
            </p>
          </li>
          <li>
            <img src={icon6} alt="icon6" />
            <p className="sub_title4">
              국제기구
              <br />
              <span className="contents3">NGO</span>
            </p>
          </li>
        </ul>
        <div>
          <NavLink to="/media/case">
            <button className="blue_btn button1">
              구축사례 바로 가기
              <img src={buttonArrow} alt="구축사례 바로가기" />
            </button>
          </NavLink>
        </div>
      </div>

      <div className="solution">
        <div className="container">
          <div className="solution_container">
            <img src={intro3} alt="intro3" />
            <div className="solution_textbox">
              <h2 className="heading1">AI SOLUTION</h2>
              <p className="sub_title4">
                레인보우브레인의 AI 솔루션을 통해 비즈니스에 새로운 가능성을
                열어보세요.
              </p>
              <ul className="solution_wrap">
                <li>
                  <span className="contents2">RPA</span>
                  <p className="contents2">업무자동화 솔루션 공급 및 구축</p>
                </li>
                <li>
                  <span className="contents2">RPA Portal</span>
                  <p className="contents2">
                    업무자동화 중앙 관제 및 통합 관리​
                  </p>
                </li>
                <li>
                  <span className="contents2">OCR</span>
                  <p className="contents2">
                    머신러닝을 통한 문서 자동 인식 솔루션​
                  </p>
                </li>
                <li>
                  <span className="contents2">E-commerce</span>
                  <p className="contents2">
                    상품, 가격, 댓글 분석 자동화 서비스
                  </p>
                </li>
                <li>
                  <span className="contents2">GenAI</span>
                  <p className="contents2">
                    Knowledge Base 서비스, 프롬프트 엔지니어링, 임베딩 모델,
                    파인튜닝, PII 필터링 기술​
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <NavLink to="/business/aisolution" className="navlink">
              <button className="blue_btn button1">
                AI SOLUTION 바로 가기
                <img src={buttonArrow} alt="AI SOLUTION 바로가기" />
              </button>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IntroductionPage;
