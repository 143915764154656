import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollTop from "../ScrollTop.js";
import SideBox from "../SideBox.js";
import Content from "../../components/ContentHeader.js";
import Route from "../../components/Route.js";

// css
import "../../css/News.css";
import "../../css/NewsPage.css";
import "../../css/Conference.css";

// img
import Cont08 from "../../assets/img/web_cont08.png";

function Contents08() {
  return (
    <div className="webinar_wrap">
      <ScrollTop />

      <SideBox />

      {/* 페이지 타이틀명 설정 */}
      <Helmet>
        <title>레인보우브레인 | 지능형자동화 전문기업</title>
      </Helmet>
      <Content
        title="CONFERENCE"
        title1="콘텐츠를 통해 인사이트를 얻어보세요"
      />

      <Route route1="MEDIA" route2="CONFERENCE"></Route>

      <div className="contents_wrap content_bg">
        <div className="container">
          <div className="news_box">
            <div className="news_badge">
              <span>ONLINE</span>
            </div>

            <div className="news_content">
              <img src={Cont08} alt="이미지" />

              <div className="text_box">
                <p>
                  이제 LLM은 선택이 아니라 필수로 다가오고 있다.
                  <br />
                  데이터 거버넌스, 보안과 저작권 문제, 인프라 확충, 기존
                  시스템/서비스와 연계하거나 신규 시스템/서비스를 개발을 포함해
                  업무 효율성을 위해 LLM을 도입하는 과정에서 대비해야 하는
                  사안을 총정리한다.
                </p>
              </div>
            </div>

            <div className="btn_webinar">공개예정</div>

            <div className="news_btn">
              <Link to="/media/Conference">
                <button className="btn_prev">목록</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contents08;
