import { Helmet } from "react-helmet";
import ScrollTop from "./ScrollTop";
// import SideBox from "./SideBox.js";
import Content from "../components/ContentHeader";
import Route from "../components/Route";
// import { Link, NavLink } from "react-router-dom";

// css
import "../css/AiSolutionPage.css";

//img
import buttonArrow from "../assets/img/button_arrow_icon.png";
import aiIcon2 from "../assets/img/ai_icon2.png";
import aiIcon3 from "../assets/img/ai_icon3.png";
import aiIcon4 from "../assets/img/ai_icon4.png";

const data = [
  {
    icon: aiIcon3,
    heading: "EurekaGenAI",
    description:
      "기업이 보유한 문서와 지식을 AI가 사용할 수 있도록 지식베이스 데이터베이스로 구축하고, 도메인 전문성을 가지도록 사전 학습된 기업 특화 생성형 AI 솔루션입니다.",
    button1: "소개 페이지 바로가기",
    button2: "소개 자료 보기",
    link1: "http://rbrain.co.kr/business/eurekagenai",
    link2:
      "https://s3.ap-northeast-2.amazonaws.com/rbrain.co.kr/images/EurekaChat_2024.pdf",
    // link2: "https://s3.ap-northeast-2.amazonaws.com/rbrain.co.kr/images/EurekaChat_Brochure_v6.pdf",
  },
  {
    icon: aiIcon4,
    heading: "Trend Sensing Board",
    description:
      "고객이 효과적으로 트렌드 분석을 하기 위해 데이터 시각화를 제공하여 데이터 기반의 수준 높은 비즈니스 결정을 지원하는 ‘트렌드 자동화 솔루션’ 입니다.",
    button1: "소개 페이지 바로가기",
    button2: "",
    link1: "http://rbrain.co.kr/solution/trend.html",
    link2: "",
  },
  {
    icon: aiIcon2,
    heading: "EurekaBrain",
    description:
      "커머스 시장에서 소비재 브랜드가 지속적으로 선재 대응할 수 있도록 데이터를 빠르게 수집하고 분석 및 대응을 자동화할 수 있는 '데이터 자동화 솔루션' 입니다.",
    button1: "소개 페이지 바로가기",
    button2: "",
    link1: "http://rbrain.co.kr/solution/index.html",
    link2: "",
  },
];

// const linkElement = `<a href="${data[0].link}" target="_blank">${data[0].button}</a>`;

function AiSolutionPage() {
  return (
    <div className="ai_solution_page">
      <ScrollTop />

      {/* <SideBox /> */}

      {/* 페이지 타이틀명 설정 */}
      <Helmet>
        <title>레인보우브레인 | 지능형자동화 전문기업</title>
        <meta
          name="description"
          content="자사가 개발한 인공지능 및 업무 자동화 분야 솔루션을 소개합니다."
        ></meta>
      </Helmet>
      <Content title="AI SOLUTION" title1=""></Content>

      <Route route1="BUSINESS" route2="AI SOLUTION"></Route>
      <div className="content_bg">
        <div className="container">
          {data.map((item, index) => (
            <div className={`page_box cont${index + 1}`} key={index}>
              <div className="icon_img">
                <img src={item.icon} alt="아이콘 이미지" />
              </div>

              <div className="text_box">
                <p className="heading2">{item.heading}</p>
                <p className="contents1">{item.description}</p>
                <div>
                  <a
                    href={item.link1}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="blue_btn button1">
                      {item.button1}
                      <img src={buttonArrow} alt="바로가기 버튼" />
                    </button>
                  </a>

                  {/* 다음 버튼이 있는 경우에만 렌더링 */}
                  {item.button3 && (
                    <a
                      href={item.link3}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button className="blue_btn button3">
                        {item.button3}
                        <img src={buttonArrow} alt="바로가기 버튼" />
                      </button>
                    </a>
                  )}

                  {item.button2 && (
                    <a
                      href={item.link2}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button className="blue_btn button2">
                        {item.button2}
                        <img src={buttonArrow} alt="바로가기 버튼" />
                      </button>
                    </a>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AiSolutionPage;
