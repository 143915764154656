const BASE_URL = "https://server.tsb.today";
// const BASE_URL = "http://192.168.0.137:8080";

export async function newsletterCreate(params) {
  console.log(params);

  const response = await fetch(`${BASE_URL}/newsletters/create`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(params)
  });

  // response 응답 체크 후 에러 발생로직
  if (!response.ok) {
    throw new Error("url 등록 실패했습니다.");
  }
  const body = await response.json();
  // console.log(body);
  return body;
}

export async function newsletterUpdate(id, uploadData) {
  console.log(uploadData);
  const response = await fetch(`${BASE_URL}/newsletters/update/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(uploadData)
  });

  // response 응답 체크 후 에러 발생로직
  if (!response.ok) {
    throw new Error("Notice를 수정하는데 실패했습니다.");
  }
  const body = await response.json();
  // console.log(body);
  return body;
}

export async function newsletterDelete(id) {
  console.log(id);

  const response = await fetch(`${BASE_URL}/newsletters/delete/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json"
    }
  });

  // response 응답 체크 후 에러 발생로직
  if (!response.ok) {
    throw new Error("Notice를 삭제하는데 실패했습니다.");
  }
  const body = await response.json();
  // console.log(body);
  return body;
}

export async function getLetterCount(options) {
  const offset = 0;
  const limit = 10000;

  const keyword = options.keyword || "";
  const startDate = options.startDate || "";
  const endDate = options.endDate || "";

  // 쿼리 문자열에 기본값을 반영
  const query = `selectedOption=post_date&keywordSelectedOption=all&offset=${offset}&limit=${limit}&keyword=${keyword}&startDate=${startDate}&endDate=${endDate}`;
  const response = await fetch(`${BASE_URL}/newsletters/getall?${query}`);

  if (!response.ok) {
    throw new Error("news total count 가져오기 실패.");
  }
  const body = await response.json();
  console.log(body);
  return body;
}

export async function getLetter(options) {
  const offset = options.offset;
  const limit = options.limit;

  const keyword = options.keyword || "";
  const startDate = options.startDate || "";
  const endDate = options.endDate || "";

  // 쿼리 문자열에 기본값을 반영
  const query = `selectedOption=post_date&keywordSelectedOption=all&offset=${offset}&limit=${limit}&keyword=${keyword}&startDate=${startDate}&endDate=${endDate}`;
  const response = await fetch(`${BASE_URL}/newsletters/getall?${query}`);

  // response 응답 체크 후 에러 발생로직
  if (!response.ok) {
    throw new Error("공지사항 정보를 불러오는데 실패했습니다.");
  }
  const body = await response.json();
  console.log(body);
  return body;
}

export async function click_count_update(id, uploadData) {
  // console.log("조회수증가", uploadData);
  const response = await fetch(`${BASE_URL}/newsletters/clickcount/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(uploadData)
  });

  // response 응답 체크 후 에러 발생로직
  if (!response.ok) {
    throw new Error("Notice를 수정하는데 실패했습니다.");
  }
  const body = await response.json();
  // console.log(body);
  return body;
}

export async function constructions_click(id, uploadData) {
  // console.log("조회수증가", uploadData);
  const response = await fetch(`${BASE_URL}/constructions/clickcount/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(uploadData)
  });

  // response 응답 체크 후 에러 발생로직
  if (!response.ok) {
    throw new Error("Notice를 수정하는데 실패했습니다.");
  }
  const body = await response.json();
  // console.log(body);
  return body;
}

export async function news_click(id, uploadData) {
  // console.log("뉴스조회수증가", uploadData);
  const response = await fetch(`${BASE_URL}/news/clickcount/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(uploadData)
  });

  // response 응답 체크 후 에러 발생로직
  if (!response.ok) {
    throw new Error("Notice를 수정하는데 실패했습니다.");
  }
  const body = await response.json();
  // console.log(body);
  return body;
}
