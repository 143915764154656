// 임시 바우처 페이지

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { getVouchers } from "../api/api.js";
// import SideBox from "./SideBox.js";
import ScrollTop from "./ScrollTop";
import Content from "../components/ContentHeader";
import Route from "../components/Route";

// css
import "../css/Partners.css";

function Voucher() {
  const [data, setData] = useState([]);
  // console.log(data);

  const handleLoad = async () => {
    const result = await getVouchers();
    setData(result);
  };

  useEffect(() => {
    handleLoad();
  }, []);
  return (
    <div className="partners_wrap voucher_wrap">
      <ScrollTop />

      {/* <SideBox /> */}

      {/* 페이지 타이틀명 설정 */}
      <Helmet>
        <title>레인보우브레인 | 지능형자동화 전문기업</title>
      </Helmet>
      <Content title="VOUCHER" title1=""></Content>

      <Route route1="BUSINESS" route2="VOUCHER"></Route>
      <div className="content_bg">
        <div className="container">
          <div className="partners_box">
            {data.length > 0 && (
              <div
                className="partners_cont"
                dangerouslySetInnerHTML={{ __html: data[0].content }}
              ></div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Voucher;
