import React, { useState, useEffect } from "react";
import { getNews } from "../api/api.js";
import { useLocation, Link } from "react-router-dom";
import ScrollTop from "./ScrollTop";
import Content from "../components/ContentHeader";
import Route from "../components/Route";

// css
import "../css/NewsPage.css";

// img
import buttonArrow from "../assets/img/news_btn_arrow.png";

function NewsDetail() {
  const location = useLocation();
  const { item } = location.state;

  const [detail_title, setDetail_title] = useState(item.title);
  const [detail_division, setDetail_division] = useState(item.division);

  const [detail_content, setDetail_content] = useState(item.content);

  const [detail_item, setDetail_item] = useState([]);

  const [itemId, setItemId] = useState(item.id);

  // 최초 렌더링하면서 뉴스데이터를 newDetail로 가져오는 함수.
  const handleLoad = async () => {
    const newsDetail = await getNews();
    setDetail_item(newsDetail);
  };

  // 다음 버튼 클릭할 때 실행함수,
  const handleNextButtonClick = () => {
    const currentIndex = detail_item.findIndex((i) => i.id === itemId);
    const nextIndex = currentIndex + 1;
    setItemId(detail_item[nextIndex].id);
    setDetail_title(detail_item[nextIndex].title);
    setDetail_division(detail_item[nextIndex].division);

    setDetail_content(detail_item[nextIndex].content);
  };

  // 뒤로 버튼 클릭할 때 실행함수,
  const handlePrevButtonClick = () => {
    const currentIndex = detail_item.findIndex((i) => i.id === itemId);
    const nextIndex = currentIndex - 1;
    setItemId(detail_item[nextIndex].id);
    setDetail_title(detail_item[nextIndex].title);
    setDetail_division(detail_item[nextIndex].division);

    setDetail_content(detail_item[nextIndex].content);
  };

  useEffect(() => {
    handleLoad();
  }, []);

  return (
    <div className="news_page_detail_wrap">
      <ScrollTop />

      {/* <SideBox /> */}

      <Content
        title="NEWS"
        title1="레인보우브레인의 새로운 소식을 전해드려요"
      ></Content>

      <Route route1="MEDIA" route2="NEWS"></Route>
      <div className="content_bg">
        <div className="container">
          <div className="news_box">
            <div className="news_box_badge contents3">
              <span>{detail_division}</span>
            </div>
            <p className="sub_title2 news_title">{detail_title}</p>
            <p
              className="contents2 news_content image"
              dangerouslySetInnerHTML={{
                __html: detail_content.replace(/&nbsp;/g, ""),
              }}
            ></p>

            <div className="news_btn">
              <button
                className="btn_prev contents3"
                onClick={handlePrevButtonClick}
              >
                <img src={buttonArrow} alt="이전" />
                Prev
              </button>
              <Link to="/media/news">
                <button className="btn_list contents3 ">목록</button>
              </Link>
              <button
                className="btn_next contents3"
                onClick={handleNextButtonClick}
              >
                Next
                <img src={buttonArrow} alt="다음" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsDetail;
