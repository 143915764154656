import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollTop from "../ScrollTop.js";
// import SideBox from "../SideBox.js";
import Content from "../../components/ContentHeader.js";
import Route from "../../components/Route.js";

// css
import "../../css/News.css";
import "../../css/NewsPage.css";
import "../../css/Conference.css";

// img
import Cont02 from "../../assets/img/web_cont02.png";

function Contents02() {
  return (
    <div className="webinar_wrap">
      <ScrollTop />

      {/* <SideBox /> */}

      {/* 페이지 타이틀명 설정 */}
      <Helmet>
        <title>레인보우브레인 | 지능형자동화 전문기업</title>
      </Helmet>
      <Content
        title="CONFERENCE"
        title1="콘텐츠를 통해 인사이트를 얻어보세요"
      />

      <Route route1="MEDIA" route2="CONFERENCE"></Route>

      <div className="contents_wrap content_bg">
        <div className="container">
          <div className="news_box">
            <div className="news_badge">
              <span>ONLINE</span>
            </div>

            <div className="news_content">
              <img src={Cont02} alt="이미지" />

              <div className="text_box">
                <span>행사 소개</span>
                <table className="intro">
                  <tr>
                    <th>주제</th>
                    <td style={{ lineHeight: "26px" }}>
                      생성형 AI 도입을 위한 기업 나침반 - 도입 목적별 AI 모델,
                      개발, 인프라 선택 고려사항
                      <br />
                      Session 1. 도입 목적별 AI 모델, 개발, 인프라 선택 고려사항
                      <br />
                      Session 2. 레인보우브레인 sLLM '유레카GenAI'제품 소개
                    </td>
                  </tr>
                  <tr>
                    <th>일시</th>
                    <td>2024년 4월 26일(금) | 오전 10:00~11:00 (QA포함)</td>
                  </tr>
                  {/* <tr>
                    <th>신청</th>
                    <td>아래 [등록하기] 클릭하여 신청 양식 제출</td>
                  </tr> */}
                  <tr>
                    <th>비용</th>
                    <td>무료</td>
                  </tr>
                  <tr>
                    <th>장소</th>
                    <td>온라인 진행 행사</td>
                  </tr>
                  <tr>
                    <th>채널</th>
                    <td>YouTube</td>
                  </tr>
                </table>

                <p style={{ paddingTop: "32px" }}>
                  생성형 AI 기술을 둘러싼 생태계의 구성 요소와 고려 사항을
                  살펴보고, 생성형 AI 모델을 선택할 때, 생성형 AI 개발 환경을
                  선택할 때, 생성형 AI 인프라를 선택할 때 고려 사항을 구체적인
                  사례로 정리한다.
                  <br />
                  상용 소프트웨어/모델과 오픈 소스 소프트웨어/모델이 계속해서
                  발전하는 상황이므로, 지속적으로 기술 생태계에 주목하고 핵심
                  기술을 선택해서 발전시켜 나가야 치열한 경쟁 구도에서 낙오자가
                  되지 않을 것이다.
                </p>
              </div>
            </div>

            <a
              href="https://m.site.naver.com/1mnss"
              target="_blank"
              rel="noopener noreferrer"
              className="btn_webinar"
            >
              시청하기
            </a>

            <div className="news_btn">
              <Link to="/media/Conference">
                <button className="btn_prev">목록</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contents02;
