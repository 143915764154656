import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollTop from "../ScrollTop.js";
// import SideBox from "../SideBox.js";
import Content from "../../components/ContentHeader.js";
import Route from "../../components/Route.js";

// css
import "../../css/News.css";
import "../../css/NewsPage.css";
import "../../css/Conference.css";

// img
import Cont05 from "../../assets/img/web_cont05.png";

function Contents05() {
  return (
    <div className="webinar_wrap">
      <ScrollTop />

      {/* <SideBox /> */}

      {/* 페이지 타이틀명 설정 */}
      <Helmet>
        <title>레인보우브레인 | 지능형자동화 전문기업</title>
      </Helmet>
      <Content
        title="CONFERENCE"
        title1="콘텐츠를 통해 인사이트를 얻어보세요"
      />

      <Route route1="MEDIA" route2="CONFERENCE"></Route>

      <div className="contents_wrap content_bg">
        <div className="container">
          <div className="news_box">
            <div className="news_badge">
              <span>ONLINE</span>
            </div>

            <div className="news_content">
              <img src={Cont05} alt="이미지" />

              <div className="text_box">
                <span>행사 소개</span>
                <table className="intro">
                  <tr>
                    <th>주제</th>
                    <td style={{ lineHeight: "26px" }}>
                      에이전트와 멀티 모달로 강화되는 RAG
                      <br />
                      Session 1. 엣지향 LLM의 등장 Q&A
                      <br />
                      Session 2. 에이전트와 멀티 모달로 강화되는 RAG
                      <br />
                      Session 3. Upstage - Document AI 소개 및 기업 적용 사례
                    </td>
                  </tr>
                  <tr>
                    <th>일시</th>
                    <td>2024년 7월 31일(수) | 오후 02:00~03:00 (QA 포함)</td>
                  </tr>
                  <tr>
                    <th>신청</th>
                    <td>아래 [등록하기] 클릭하여 신청 양식 제출</td>
                  </tr>
                  <tr>
                    <th>비용</th>
                    <td>무료</td>
                  </tr>
                  <tr>
                    <th>장소</th>
                    <td>온라인 진행 행사</td>
                  </tr>
                  <tr>
                    <th>채널</th>
                    <td>YouTube</td>
                  </tr>
                </table>

                <p style={{ paddingTop: "32px" }}>
                  LLM의 환각을 줄이고 기업내 지식 기반을 축적해서 정확한 정보를
                  전달하기 위해 고안된 RAG는 이제 에이전트와 결합해 외부 정보를
                  유연하게 통합하고 멀티 모달 기능으로 텍스트 뿐만 아니라
                  이미지까지 확장될 것으로 예상한다.
                  <br />
                  기존 기업 내 지식을 관리하는 인프라인 DMS(Document Management
                  System)와 KMS(Knowledge Management System)와 비교해 어떤
                  특장점이 있는지 일목요연하게 정리한다.
                </p>
              </div>
            </div>

            <a
              href="https://m.site.naver.com/1rk5j"
              target="_blank"
              rel="noopener noreferrer"
              className="btn_webinar"
            >
              시청하기
            </a>

            <div className="news_btn">
              <Link to="/media/Conference">
                <button className="btn_prev">목록</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contents05;
