// const BASE_URL = "http://192.168.0.137:8080";

// const BASE_URL =
//   "http://ec2-3-37-129-252.ap-northeast-2.compute.amazonaws.com:8080";

// const BASE_URL =
//   "https://rainbow-brain-backend-server-env.eba-23au4jxd.ap-northeast-2.elasticbeanstalk.com";

const BASE_URL = "https://server.tsb.today";

// -- news table의 총 row를 추출하기 위해 offset 0 ,  limit 10000 설정 -- //
export async function getNewsCount(options) {
  const division = options.division;
  const open = options.open;
  const selectedOption = options.selectedOption;
  const keywordSelectedOption = options.keywordSelectedOption;
  const startDate = options.startDate;
  const endDate = options.endDate;
  const keyword = options.search;
  const offset = options.offset;
  const limit = options.limit;

  const query = `open=${open}&division=${division}&selectedOption=${selectedOption}&keywordSelectedOption=${keywordSelectedOption}&keyword=${keyword}&startDate=${startDate}&endDate=${endDate}&offset=${offset}&limit=${limit}`;
  const response = await fetch(`${BASE_URL}/news/?${query}`);

  if (!response.ok) {
    throw new Error("news total count 가져오기 실패.");
  }
  const body = await response.json();
  return body;
}

export async function getNews() {
  const division = "";
  const open = "1";
  const selectedOption = "postDate";
  const keywordSelectedOption = "";
  const startDate = "";
  const endDate = "";
  const keyword = "";
  const offset = 0;
  const limit = 10000;

  // console.log(division);
  // console.log(selectedOption);
  // console.log(startDate);
  // console.log(endDate);
  // console.log(title);
  // console.log(offset);
  // console.log(limit);
  const query = `open=${open}&division=${division}&selectedOption=${selectedOption}&keywordSelectedOption=${keywordSelectedOption}&keyword=${keyword}&startDate=${startDate}&endDate=${endDate}&offset=${offset}&limit=${limit}`;
  const response = await fetch(`${BASE_URL}/news/?${query}`);

  // console.log(query);

  // response 응답 체크 후 에러 발생로직
  if (!response.ok) {
    throw new Error("공지사항 정보를 불러오는데 실패했습니다.");
  }
  const body = await response.json();
  // console.log(body);
  return body;
}

export async function getNewsId(id) {
  const response = await fetch(`${BASE_URL}/news/${id}`);

  // response 응답 체크 후 에러 발생로직
  if (!response.ok) {
    throw new Error("공지사항 정보를 불러오는데 실패했습니다.");
  }
  const body = await response.json();
  // console.log(body);
  return body;
}

//────────────────────────────────── [ ▲ News ] ──────────────────────────────────//

export async function getCase() {
  const open = "1";
  const selectedOption = "postDate";
  const startDate = "";
  const endDate = "";
  const title = "";
  const offset = 0;
  const limit = 10000;

  // console.log(selectedOption);
  // console.log(startDate);
  // console.log(endDate);
  // console.log(title);
  // console.log(offset);
  // console.log(limit);

  const query = `open=${open}&selectedOption=${selectedOption}&title=${title}&startDate=${startDate}&endDate=${endDate}&offset=${offset}&limit=${limit}`;
  const response = await fetch(`${BASE_URL}/constructions/?${query}`);

  // response 응답 체크 후 에러 발생로직
  if (!response.ok) {
    throw new Error("구축사례 정보를 불러오는데 실패했습니다.");
  }
  const body = await response.json();
  // console.log(body);
  return body;
}

//────────────────────────────────── [ ▲ Case ] ───────────────────────────────//

// 백엔드에서 a360으로 api 요청해서 실행함.

export async function getAuthToken(inputData) {
  console.log(inputData);
  const response = await fetch(`${BASE_URL}/a360`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(inputData)
  });

  // response 응답 체크 후 에러 발생로직
  if (!response.ok) {
    throw new Error("a360 인증토큰 정보를 불러오는데 실패했습니다.");
  }
  const body = await response.json();
  // console.log(body);
  return body;
}

export async function getAnswer(inputData) {
  const question = inputData.input1;

  const query = `question=${question}`;
  const response = await fetch(`${BASE_URL}/a360/answer?${query}`);

  // console.log(query);
  // console.log(inputData.input1);

  // response 응답 체크 후 에러 발생로직
  if (!response.ok) {
    throw new Error("A360 답변 정보를 불러오는데 실패했습니다.");
  }
  const body = await response.json();
  // console.log(body);
  return body;
}

//────────────────────────────────── [ ▲ A360_FAQ ] ──────────────────────────────────//

// 백엔드에서 a360으로 api 요청해서 실행함.

export async function getAuthToken_2(inputData) {
  console.log(inputData);
  const response = await fetch(`${BASE_URL}/a360_2`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(inputData)
  });

  // response 응답 체크 후 에러 발생로직
  if (!response.ok) {
    throw new Error("a360 인증토큰 정보를 불러오는데 실패했습니다.");
  }
  const body = await response.json();
  // console.log(body);
  return body;
}

//────────────────────────────────── [ ▲ A360_금칙어 ] ───────────────────────────────//

export async function inquirysCreate(uploadData) {
  // console.log(formData);

  const response = await fetch(`${BASE_URL}/inquirys/create`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(uploadData)
  });

  // response 응답 체크 후 에러 발생로직
  if (!response.ok) {
    throw new Error("문의하기를 등록하는데 실패했습니다.");
  }
  const body = await response.json();
  // console.log(body);
  return body;
}

export async function sendMail(uploadData) {
  // console.log(formData);

  const response = await fetch(`${BASE_URL}/sendMail`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(uploadData)
  });

  // response 응답 체크 후 에러 발생로직
  if (!response.ok) {
    throw new Error("메일발송 실패했습니다.");
  }
  const body = await response.json();
  // console.log(body);
  return body;
}

//────────────────────────────────── [ ▲ Support 문의하기 ] ───────────────────────//

// -- news table의 총 row를 추출하기 위해 offset 0 ,  limit 10000 설정 -- //
// -- 나중에 수정 해야 될 부분 임 -- //
export async function getFaqsCount(options) {
  const open = options.open;
  const selectedOption = options.selectedOption;
  const keywordSelectedOption = options.keywordSelectedOption;
  const startDate = options.startDate;
  const endDate = options.endDate;
  const keyword = options.search;
  const offset = 0;
  const limit = 10000;

  const query = `open=${open}&selectedOption=${selectedOption}&keywordSelectedOption=${keywordSelectedOption}&keyword=${keyword}&startDate=${startDate}&endDate=${endDate}&offset=${offset}&limit=${limit}`;
  const response = await fetch(`${BASE_URL}/faqs/?${query}`);

  if (!response.ok) {
    throw new Error("news total count 가져오기 실패.");
  }
  const body = await response.json();
  return body;
}

export async function getFaqs(options) {
  const open = options.open;
  const selectedOption = options.selectedOption;
  const keywordSelectedOption = options.keywordSelectedOption;
  const startDate = options.startDate;
  const endDate = options.endDate;
  const keyword = options.search;
  const offset = options.offset;
  const limit = options.limit;

  // console.log(selectedOption);
  // console.log(startDate);
  // console.log(endDate);
  // console.log(title);
  // console.log(offset);
  // console.log(limit);

  const query = `open=${open}&selectedOption=${selectedOption}&keywordSelectedOption=${keywordSelectedOption}&keyword=${keyword}&startDate=${startDate}&endDate=${endDate}&offset=${offset}&limit=${limit}`;
  const response = await fetch(`${BASE_URL}/faqs/?${query}`);

  // response 응답 체크 후 에러 발생로직
  if (!response.ok) {
    throw new Error("구축사례 정보를 불러오는데 실패했습니다.");
  }
  const body = await response.json();
  // console.log(body);
  return body;
}

//────────────────────────────────── [ ▲ FAQ ] ───────────────────────────────────//

// -- popup table의 총 row를 추출하기 위해 offset 0 ,  limit 10000 설정 -- //
export async function getPopupsCount() {
  const selectedOption = "updatedAt";
  const startDate = "";
  const endDate = "";
  const title = "";
  const open = 1;

  const offset = 0;
  const limit = 10000;

  const query = `open=${open}&selectedOption=${selectedOption}&title=${title}&startDate=${startDate}&endDate=${endDate}&offset=${offset}&limit=${limit}`;
  const response = await fetch(`${BASE_URL}/popups/?${query}`);

  if (!response.ok) {
    throw new Error("Popups total count 가져오기 실패.");
  }
  const body = await response.json();
  return body;
}

// -- popup 게시글 데이터 조회 -- //
export async function getPopups() {
  const selectedOption = "updatedAt";
  const startDate = "";
  const endDate = "";
  const title = "";
  const open = 1;

  const offset = 0;
  const limit = 10000;

  // console.log(selectedOption);
  // console.log(startDate);
  // console.log(endDate);
  // console.log(open);
  // console.log(title);

  // console.log(offset);
  // console.log(limit);

  const query = `open=${open}&selectedOption=${selectedOption}&title=${title}&startDate=${startDate}&endDate=${endDate}&offset=${offset}&limit=${limit}`;
  const response = await fetch(`${BASE_URL}/popups/?${query}`);

  // response 응답 체크 후 에러 발생로직
  if (!response.ok) {
    throw new Error("Popups 정보를 불러오는데 실패했습니다.");
  }
  const body = await response.json();
  // console.log(body);
  return body;
}

//────────────────────────────────── [ ▲ POPUP ] ───────────────────────────────────//

export async function getPartners() {
  const open = "1";
  const selectedOption = "postDate";
  const startDate = "";
  const endDate = "";
  const title = "";
  const offset = 0;
  const limit = 10000;

  // console.log(selectedOption);
  // console.log(startDate);
  // console.log(endDate);
  // console.log(title);
  // console.log(offset);
  // console.log(limit);

  const query = `open=${open}&selectedOption=${selectedOption}&title=${title}&startDate=${startDate}&endDate=${endDate}&offset=${offset}&limit=${limit}`;
  const response = await fetch(`${BASE_URL}/partners/?${query}`);

  // response 응답 체크 후 에러 발생로직
  if (!response.ok) {
    throw new Error("구축사례 정보를 불러오는데 실패했습니다.");
  }
  const body = await response.json();
  // console.log(body);
  return body;
}

//────────────────────────────────── [ ▲ Partners ] ───────────────────────────────//

export async function imageUpload(formData) {
  // console.log(formData);

  const response = await fetch(`${BASE_URL}/imgUpload`, {
    method: "POST",
    body: formData
  });

  // response 응답 체크 후 에러 발생로직
  if (!response.ok) {
    throw new Error("Notice를 생성하는데 실패했습니다.");
  }
  const body = await response.json();
  // console.log(body);
  return body;
}

//────────────────────────────────── [ ▲ image ] ──────────────────────────────────//

export async function getVouchers() {
  const open = "1";
  const selectedOption = "postDate";
  const startDate = "";
  const endDate = "";
  const title = "";
  const offset = 0;
  const limit = 10000;

  // console.log(selectedOption);
  // console.log(startDate);
  // console.log(endDate);
  // console.log(title);
  // console.log(offset);
  // console.log(limit);

  const query = `open=${open}&selectedOption=${selectedOption}&title=${title}&startDate=${startDate}&endDate=${endDate}&offset=${offset}&limit=${limit}`;
  const response = await fetch(`${BASE_URL}/voucher/?${query}`);

  // response 응답 체크 후 에러 발생로직
  if (!response.ok) {
    throw new Error("구축사례 정보를 불러오는데 실패했습니다.");
  }
  const body = await response.json();
  // console.log(body);
  return body;
}

//────────────────────────────────── [ ▲ 바우처 ] ───────────────────────────────//
