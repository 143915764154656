import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollTop from "../ScrollTop.js";
// import SideBox from "../SideBox.js";
import Content from "../../components/ContentHeader.js";
import Route from "../../components/Route.js";

// css
import "../../css/News.css";
import "../../css/NewsPage.css";
import "../../css/Conference.css";

// img
import Cont01 from "../../assets/img/web_cont01.png";

function Contents01() {
  return (
    <div className="webinar_wrap">
      <ScrollTop />

      {/* <SideBox /> */}

      {/* 페이지 타이틀명 설정 */}
      <Helmet>
        <title>레인보우브레인 | 지능형자동화 전문기업</title>
      </Helmet>
      <Content
        title="CONFERENCE"
        title1="콘텐츠를 통해 인사이트를 얻어보세요"
      />

      <Route route1="MEDIA" route2="CONFERENCE"></Route>

      <div className="contents_wrap content_bg">
        <div className="container">
          <div className="news_box">
            <div className="news_badge badge_off">
              <span>OFFLINE</span>
            </div>

            <div className="news_content">
              <img src={Cont01} alt="이미지" />

              <div className="text_box">
                <p>
                  여러 산업 분야에서 디지털 전환(Digital Transformation)을
                  도입하고 있고, 그 기반에는 전문적인 자동화 기술이 존재합니다.
                  오토메이션애니웨어(Automation Anywhere)는 국제적인 RPA(Robot
                  Processing Automation, 자동화) 전문 솔루션 업체로
                  레인보우브레인(Rainbow Brain)과 파트너십을 맺어 금융, 제조,
                  공공 및 유통 분야의 국내 다양한 업체에 RPA 기술을
                  도입하였습니다.
                  <br />
                  GPT와 같은 생성형 AI(Generative AI)가 등장함에 따라 RPA 기술에
                  AI를 연동하여 단순 규칙 기반으로 작동하던 자동화를 더욱 지능적
                  탈바꿈하게 되었습니다. 이번 세미나를 RPA와 생성형 AI를 연동할
                  수 있는 사례를 소개하는 자리가 되었습니다.
                  <br /> <br />
                  Opening – 인사말(Greeting): 오토메이션애니웨어 이문형 지사장,
                  레인보우브레인 박현호 대표
                  <br />
                  Session 1 – Put AI To Work with the Automation Success
                  Platform, 오토메이션애니웨어 이동언 부장
                  <br />
                  Session 2 – Gen AI + RPA 사례 소개(마켓 트렌드 대시보드),
                  레인보우브레인 이형호 이사
                  <br />
                  Session 3 – 기업형 LLM 구축 방법론 소개, 레인보우브레인 박현호
                  대표
                </p>
              </div>
            </div>

            <a
              href="https://blog.naver.com/rainbow-brain/223339609696"
              target="_blank"
              rel="noopener noreferrer"
              className="btn_webinar"
            >
              보러가기
            </a>

            <div className="news_btn">
              <Link to="/media/Conference">
                <button className="btn_prev">목록</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contents01;
