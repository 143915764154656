import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollTop from "../ScrollTop.js";
// import SideBox from "../SideBox.js";
import Content from "../../components/ContentHeader.js";
import Route from "../../components/Route.js";

// css
import "../../css/News.css";
import "../../css/NewsPage.css";
import "../../css/Conference.css";

// img
import Cont04 from "../../assets/img/web_cont04.png";

function Contents04() {
  return (
    <div className="webinar_wrap">
      <ScrollTop />

      {/* <SideBox /> */}

      {/* 페이지 타이틀명 설정 */}
      <Helmet>
        <title>레인보우브레인 | 지능형자동화 전문기업</title>
      </Helmet>
      <Content
        title="CONFERENCE"
        title1="콘텐츠를 통해 인사이트를 얻어보세요"
      />

      <Route route1="MEDIA" route2="CONFERENCE"></Route>

      <div className="contents_wrap content_bg">
        <div className="container">
          <div className="news_box">
            <div className="news_badge">
              <span>ONLINE</span>
            </div>

            <div className="news_content">
              <img src={Cont04} alt="이미지" />

              <div className="text_box">
                <span>행사 소개</span>
                <table className="intro">
                  <tr>
                    <th>주제</th>
                    <td style={{ lineHeight: "26px" }}>
                      엣지향 LLM의 등장
                      <br />
                      Session 1. LLM에서 LMM으로 생성형 AI 랜드스케이프 확장 Q&A
                      <br />
                      Session 2. 엣지향 LLM의 등장
                      <br />
                      Session 3. WWDC 2024에서 공개된 애플 인텔리전스를 생성형
                      AI 관점에서 살펴보기
                    </td>
                  </tr>
                  <tr>
                    <th>일시</th>
                    <td>2024년 6월 26일(수) | 오후 02:00~03:00 (QA 포함)</td>
                  </tr>
                  {/* <tr>
                    <th>신청</th>
                    <td>아래 [등록하기] 클릭하여 신청 양식 제출</td>
                  </tr> */}
                  <tr>
                    <th>비용</th>
                    <td>무료</td>
                  </tr>
                  <tr>
                    <th>장소</th>
                    <td>온라인 진행 행사</td>
                  </tr>
                  <tr>
                    <th>채널</th>
                    <td>YouTube</td>
                  </tr>
                </table>

                <p style={{ paddingTop: "32px" }}>
                  최근 스마트폰에서도 LLM이 동작하기 시작했고, 노트북과 패드까지
                  AI 기능이 탑재되기 시작했다.
                  <br />
                  엣지향 LLM이 관심을 끌게된 배경과 업무에 적합한 엣지향 LLM의
                  사용 사례를 살펴본 다음에 GPU, CPU, NPU을 중심으로 어떤
                  하드웨어를 선택해야 하는지 일목요연하게 정리한다.
                </p>
              </div>
            </div>

            <a
              href="https://m.site.naver.com/1oUm0"
              target="_blank"
              rel="noopener noreferrer"
              className="btn_webinar"
            >
              시청하기
            </a>

            <div className="news_btn">
              <Link to="/media/conference">
                <button className="btn_prev">목록</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contents04;
