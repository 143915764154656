import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollTop from "../ScrollTop.js";
// import SideBox from "../SideBox.js";
import Content from "../../components/ContentHeader.js";
import Route from "../../components/Route.js";

// css
import "../../css/News.css";
import "../../css/NewsPage.css";
import "../../css/Conference.css";

// img
import Cont07 from "../../assets/img/web_cont07.png";

function Contents07() {
  return (
    <div className="webinar_wrap">
      <ScrollTop />

      {/* <SideBox /> */}

      {/* 페이지 타이틀명 설정 */}
      <Helmet>
        <title>레인보우브레인 | 지능형자동화 전문기업</title>
      </Helmet>
      <Content
        title="CONFERENCE"
        title1="콘텐츠를 통해 인사이트를 얻어보세요"
      />

      <Route route1="MEDIA" route2="CONFERENCE"></Route>

      <div className="contents_wrap content_bg">
        <div className="container">
          <div className="news_box">
            <div className="news_badge">
              <span>ONLINE</span>
            </div>

            <div className="news_content">
              <img src={Cont07} alt="이미지" />

              <div className="text_box">
                <p>
                  앞서 엣지향 LLM의 반대쪽에는 클라우드향 LLM이 존재한다.
                  <br />
                  기업에서 직접 프라이빗 클라우드에 구축할 수도 있고, CSP가
                  제공하는 LLM 서비스를 사용할 수도 있고, 전문 LLM 기업이
                  제공하는 LLM 서비스를 사용할 수도 있다.
                  <br />
                  이렇게 클라우드에서 LLM을 운용하기 위한 의사 결정 과정에서
                  필요한 항목을 일목 요연하게 정리한다.
                </p>
              </div>
            </div>

            <div className="btn_webinar">공개예정</div>

            <div className="news_btn">
              <Link to="/media/Conference">
                <button className="btn_prev">목록</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contents07;
