import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { legacy_createStore } from "redux";
import { HelmetProvider } from "react-helmet-async";

import App from "./components/App.js";
import MainPage from "./pages/MainPage.js";
import IntroductionPage from "./pages/IntroductionPage.js";
import SupportPage from "./pages/SupportPage.js";
import RpaPage from "./pages/RpaPage.js";
import AiSolutionPage from "./pages/AiSolutionPage.js";
import NewsPage from "./pages/NewsPage.js";
import NewsDetail from "./pages/NewsPageDetail.js";
import CasePage from "./pages/CasePage.js";
import CasePageDetail from "./pages/CasePageDetail.js";
import Conference from "./pages/Conference.js";
import Contents01 from "./pages/contents/Contents01.js";
import Contents02 from "./pages/contents/Contents02.js";
import Contents03 from "./pages/contents/Contents03.js";
import Contents04 from "./pages/contents/Contents04.js";
import Contents05 from "./pages/contents/Contents05.js";
import Contents06 from "./pages/contents/Contents06.js";
import Contents07 from "./pages/contents/Contents07.js";
import Contents08 from "./pages/contents/Contents08.js";
import RPAPortal from "./pages/RPA_Portal.js";
import EurekaGenAI from "./pages/EurekaGenAI.js";
import Partners from "./pages/Partners.js";
import ExpoPage from "./pages/ExpoPage.js";
import ExpoPage1 from "./pages/ExpoPage1.js";
import ExpoPage2 from "./pages/ExpoPage2.js";
// import NotFoundPage from "./pages/NotFoundPage.js";
import NewsLetterPage from "./pages/NewsLetterPage.js";

// AI EXPO
import AiExpo from "./pages/AiExpo.js";
import AiExpoLLM from "./pages/AiExpoLLM.js";
import AiExpoECommerce from "./pages/AiExpoECommerce.js";
import AiExpoChatGPT from "./pages/AiExpoChatGPT.js";

// 임시 바우처 페이지
import Voucher from "./pages/Voucher.js";
import NewsLetterViewPage from "./pages/NewsLetterViewPage.js";
import NewsLetterViewDetail from "./pages/NewsLetterViewDetail.js";

/*
 * ─────────── [ react-redux : State(상태관리)를 전역적으로 사용 할 수 있게 해줌. ] ───────────
 * ─────────── [ Provider 컴포넌트 안에 있는 컴포넌트만 사용 가능 ] ───────────────────────────
 */
function reducer(currentState, action) {
  if (currentState === undefined) {
    return {
      loginSuccess: false
    };
  }

  const notice = { ...currentState };
  if (action.type === "success") {
    notice.loginSuccess = true;
  } else {
    notice.loginSuccess = false;
  }
  return notice;
}
const store = legacy_createStore(reducer);

/*
 *  ─────────── [↓ 화면 컴포넌트 호출 부분 ] ─────────────────────────────────────────────────
 */

function Main() {
  return (
    <BrowserRouter>
      <HelmetProvider>
        <Provider store={store}>
          <Routes>
            {/*-> element가 App컴포넌트로 설정된 컴포넌트들은 App컴포넌트에서 Outlet컴포넌트로 감싸져 있는거임 */}
            <Route path="/" element={<App />}>
              {/* - main page */}
              <Route index element={<MainPage />} />
              {/* - company page */}
              <Route path="company">
                <Route path="introduction" element={<IntroductionPage />} />
                <Route path="support" element={<SupportPage />} />
              </Route>
              {/* - business page */}
              <Route path="business">
                <Route path="rpa" element={<RpaPage />} />
                <Route path="rpaportal" element={<RPAPortal />} />
                <Route path="eurekagenai" element={<EurekaGenAI />} />
                <Route path="aisolution" element={<AiSolutionPage />} />
                <Route path="voucher" element={<Voucher />} />
              </Route>
              {/* - media page */}
              <Route path="media">
                <Route path="news" element={<NewsPage />} />
                <Route path="news/newsDetail" element={<NewsDetail />} />
                <Route path="case" element={<CasePage />} />
                <Route path="case/caseDetail" element={<CasePageDetail />} />
                <Route path="conference" element={<Conference />} />
                <Route path="contents01" element={<Contents01 />} />
                <Route path="contents02" element={<Contents02 />} />
                <Route path="contents03" element={<Contents03 />} />
                <Route path="contents04" element={<Contents04 />} />
                <Route path="contents05" element={<Contents05 />} />
                <Route path="contents06" element={<Contents06 />} />
                <Route path="contents07" element={<Contents07 />} />
                <Route path="contents08" element={<Contents08 />} />
                <Route path="newsletter" element={<NewsLetterViewPage />} />
                <Route
                  path="newsletter/detailpage"
                  element={<NewsLetterViewDetail />}
                />
              </Route>
              {/* - partners page */}
              <Route path="partners">
                <Route path="partners" element={<Partners />} />
              </Route>
              <Route path="*" element={<MainPage />} />
            </Route>
            {/* expo page  */}
            <Route path="expo">
              <Route path="rpa" element={<ExpoPage />} />
              <Route path="rpa1" element={<ExpoPage1 />} />
              <Route path="rpa2" element={<ExpoPage2 />} />
            </Route>
            <Route path="*" element={<MainPage />} />
            {/* AI EXPO KOREA */}
            <Route path="aiexpo" element={<AiExpo />} />
            <Route path="aiexpoLLM" element={<AiExpoLLM />} />
            <Route path="aiexpoECommerce" element={<AiExpoECommerce />} />
            <Route path="aiexpoChatGPT" element={<AiExpoChatGPT />} />
            {/* extra api */}
            <Route path="newslettersubscribe" element={<NewsLetterPage />} />
          </Routes>
        </Provider>
      </HelmetProvider>
    </BrowserRouter>
  );
}

export default Main;
