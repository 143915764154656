import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ScrollTop from "../ScrollTop.js";
// import SideBox from "../SideBox.js";
import Content from "../../components/ContentHeader.js";
import Route from "../../components/Route.js";

// css
import "../../css/News.css";
import "../../css/NewsPage.css";
import "../../css/Conference.css";

// img
import Cont06 from "../../assets/img/web_cont06.png";

function Contents06() {
  return (
    <div className="webinar_wrap">
      <ScrollTop />

      {/* <SideBox /> */}

      {/* 페이지 타이틀명 설정 */}
      <Helmet>
        <title>레인보우브레인 | 지능형자동화 전문기업</title>
      </Helmet>
      <Content
        title="CONFERENCE"
        title1="콘텐츠를 통해 인사이트를 얻어보세요"
      />

      <Route route1="MEDIA" route2="CONFERENCE"></Route>

      <div className="contents_wrap content_bg">
        <div className="container">
          <div className="news_box">
            <div className="news_badge">
              <span>ONLINE</span>
            </div>

            <div className="news_content">
              <img src={Cont06} alt="이미지" />

              <div className="text_box">
                <span>행사 소개</span>
                <table className="intro">
                  <tr>
                    <th>주제</th>
                    <td style={{ lineHeight: "26px" }}>
                      빅데이터를 넘어선 데이터 중심의 프로세스 정립
                      <br />
                      Session 1. 에이전트와 멀티 모달로 강화되는 RAG Q&A
                      <br />
                      Session 2. 빅데이터를 넘어선 데이터 중심의 프로세스 정립
                      <br />
                      Session 3. Upstage - 생성형 AI 활용 사례 소개 (sLLM 구축
                      및 활용 사례와 향후 발전 방향)
                    </td>
                  </tr>
                  <tr>
                    <th>일시</th>
                    <td>2024년 8월 28일(수) | 오후 02:00~03:00 (QA 포함)</td>
                  </tr>
                  <tr>
                    <th>신청</th>
                    <td>아래 [등록하기] 클릭하여 신청 양식 제출</td>
                  </tr>
                  <tr>
                    <th>비용</th>
                    <td>무료</td>
                  </tr>
                  <tr>
                    <th>장소</th>
                    <td>온라인 진행 행사</td>
                  </tr>
                  <tr>
                    <th>채널</th>
                    <td>YouTube</td>
                  </tr>
                </table>

                <p>
                  기업 내에서 LLM을 구축하거나 RAG를 구축하는 과정에서 어떤
                  데이터를 수집해 어떤 방식으로 가공할지가 무척 중요하다.
                  <br />
                  빅데이터 시절에 얻은 교훈을 토대로 기업 내부와 기업 외부의
                  데이터 뿐만이 아니라 합성 데이터를 활용하는 방식의 장단점을
                  일목요연하게 정리한다.
                </p>
              </div>
            </div>

            <a
              href="https://forms.gle/AY5AcsSn7X5sRCf17"
              target="_blank"
              rel="noopener noreferrer"
              className="btn_webinar"
            >
              등록하기
            </a>

            <div className="news_btn">
              <Link to="/media/Conference">
                <button className="btn_prev">목록</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contents06;
